import React, { useState } from 'react';
import { ModalDiv, ModalOverlay, ModalWrap } from './index.elements';


const Modal = ({ hideModal, toggleModal, children }) => {
  if (hideModal) return null;

  return [
    <ModalOverlay onClick={() => toggleModal()} />,
    <ModalWrap >
      <ModalDiv >
        {children}
      </ModalDiv>
    </ModalWrap>
  ];
}

export default Modal;