import React                from 'react';
import PaymentConfirmation  from '../../components/PaymentConfirmation';
function PaymentConfirmationPage() {

  return (
    <>
      <PaymentConfirmation 
      headline    ="Thank you for your payment!"
      description ="Check your email for confirmation and purchase details"
      actionText  ="Return to store"
      destination ="/store" />
    </>
  );
}

export default PaymentConfirmationPage;
