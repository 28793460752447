import { Timestamp }    from "firebase/firestore";
import ProviderData     from "./ProviderData";


/**
 * Class to create a User Prospect object
 */
    export default class Prospect {
    /**
     *@param {string}                       uid                 - User Prospects unique identifier
     *@param {string}                       displayName         - User Prospects preferred name
     *@param {string}                       email               - User Prospects registered email
     *@param {string}                       photoURL            - User Prospects profile image url
     *@param {number}                       accessOptionSet     - OptionSet representing levels of user access based on onboarding processes
     *@param {number}                       providerOptionSet   - OptionSet representing each authentication provider linked to user account
     *@param {Array<Object>}                providerData        - List of users authentication provider data
     *@param {Array<string>}                userRoles           - List of user roles and permissions for the user
     *@param {Timestamp}                    dateCreated         - Date the User Prospect account was created
     *@param {Timestamp}                    dateUpdated         - Date the User Prospect account was updated
     */
    
    constructor(uid, displayName, email, photoURL, accessOptionSet, providerOptionSet, providerData, userRoles, dateCreated, dateUpdated) {

        /**
         *@property {string}                uid                 - Users unique identifier
         *@property {string}                displayName         - Users preferred name
         *@property {string}                email               - Users registered email
         *@property {string}                photoURL            - Users profile image url
         *@property {number}                accessOptionSet     - OptionSet representing levels of user access based on onboarding processes
         *@property {number}                providerOptionSet   - OptionSet representing each authentication provider linked to user account
         *@property {Array<ProviderData>}   providerData        - List of users authentication provider data
         *@property {Array<string>}         userRoles           - List of user roles and permissions for the user
         *@property {Timestamp}             dateCreated         - Date the User Prospect account was created
         *@property {Timestamp}             dateUpdated         - Date the User Prospect account was updated
         */



    this.uid                = uid                 
    this.displayName        = displayName         
    this.email              = email               
    this.photoURL           = photoURL            
    this.accessOptionSet    = accessOptionSet     
    this.providerOptionSet  = providerOptionSet   
    this.providerData       = ProviderData.IterateFromDict(providerData);
    this.userRoles          = userRoles
    this.dateCreated        = dateCreated         
    this.dateUpdated        = dateUpdated         
    }

    /**
     * Function ToDict
     * @property    {Function} ToDict Returns a dictionary format of
     * @returns     {Object}
     */

    ToDict() {

        return {
            'uid'               : this.uid,
            'displayName'       : this.displayName,
            'email'             : this.email,
            'photoURL'          : this.photoURL,
            'accessOptionSet'   : this.accessOptionSet,
            'providerOptionSet' : this.providerOptionSet,
            'providerData'      : ProviderData.IterateToDict(this.providerData),
            'userRoles'         : this.userRoles,
            'dateCreated'       : this.dateCreated,
            'dateUpdated'       : this.dateUpdated
        };
    }
}

/**
 * A User Prospect
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} ProspectConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type ProspectConverter
 */

export const prospectConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send User Prospect object to database
     * @param       {Prospect}      user User Prospect to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (prospect) {


        return {

            providerData        : ProviderData.IterateToDict(prospect.providerData),
            userRoles           : prospect.userRoles, 
            uid                 : prospect.uid,
            displayName         : prospect.displayName,
            email               : prospect.email,
            photoURL            : prospect.photoURL,
            accessOptionSet     : prospect.accessOptionSet,
            providerOptionSet   : prospect.providerOptionSet,
            dateCreated         : prospect.dateCreated,
            dateUpdated         : prospect.dateUpdated

        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore Function to convert data from database into User Prospect object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options Snapshot options
     * @returns     {Prospect}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new Prospect(

            ProviderData.FromDict(data.providerData),
            data.userRoles,
            data.uid,
            data.displayName,
            data.email,
            data.photoURL,
            data.accessOptionSet,
            data.providerOptionSet,
            data.dateCreated,
            data.dateUpdated
        );
    }
}
