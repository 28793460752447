import React                    from 'react';
import LuckeyLivingLogo         from '../../assets/images/luckey-living-logo-0001.png';
import {
  useSelector,
  useDispatch }                 from 'react-redux';
import { 
  Nav,
  NavGradient,
  NavbarContainer,
  NavLogo,
  NavLogoImage,
  NavMenu,
  NavItem,
  NavLinks,
  LogoutButton, 
  ShoppingCart}                from './index.elements'
import { signOutUserStart }     from '../../redux/User/user.actions';
import { checkUserIsAdmin }     from '../../Utils';
import { selectCartItemsCount } from '../../redux/Cart/cart.selectors';

const mapState = (state) => ({
  currentUser       : state.user.currentUser,
  totalNumCartItems : selectCartItemsCount(state)
});

const Navbar = props => {
  const dispatch = useDispatch();
  const { currentUser, totalNumCartItems } = useSelector(mapState);

  const signOut = () => {
    dispatch(signOutUserStart());
  };

  return (
    <Nav >
      <NavGradient >
        <NavbarContainer>
          <NavLogo to='/'>
            <NavLogoImage src={LuckeyLivingLogo} alt="LuckeyLiving Logo"/>
          </NavLogo>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to        ='/'
                  duration  ={500}
                  exact     ='true'
                  offset    ={-80}
                  // scrollnav={ scrollnav ? 1 : 0 }
                >
                  Home
                </NavLinks>
              </NavItem>
              {/* <NavItem>
                <NavLinks
                  to        ='/contact'
                  duration  ={500}
                  exact     ='true'
                  offset    ={-80}
                  // scrollnav={ scrollnav ? 1 : 0 }
                >
                  Contact Us
                </NavLinks>
              </NavItem> */}
              <NavItem>
                <NavLinks
                  to        ='/store'
                  duration  ={500}
                  exact     ='true'
                  offset    ={-80}
                  // scrollnav={ scrollnav ? 1 : 0 }
                >
                  Shop
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  to        ='/cart'
                  duration  ={500}
                  exact     ='true'
                  offset    ={-80}
                  // scrollnav={ scrollnav ? 1 : 0 }
                >
                  <i><ShoppingCart /></i> {totalNumCartItems > 0 ? `(${totalNumCartItems})` : ''}
                </NavLinks>
              </NavItem>

            {currentUser && (
              [<NavItem>
              <NavLinks
                to        ='/dashboard'
                duration  ={500}
                exact     ='true'
                offset    ={-80}
                // scrollnav={ scrollnav ? 1 : 0 }
              >
              Account
              </NavLinks>
            </NavItem>,
            <LogoutButton onClick={() => {
              console.log("Selected Signout")  
              signOut()
              }}>
              Logout
            </LogoutButton>]
            )}
            {checkUserIsAdmin(currentUser) && (
              <NavItem>
              <NavLinks
                to        ='/admin'
                duration  ={500}
                exact     ='true'
                offset    ={-80}
                // scrollnav={ scrollnav ? 1 : 0 }
              >
              Admin
              </NavLinks>
            </NavItem>
            )}

            {!currentUser && (

              [<NavItem>
                <NavLinks
                  to        ='/signup'
                  duration  ={500}
                  exact     ='true'
                  offset    ={-80}
                  // scrollnav={ scrollnav ? 1 : 0 }
                >
                Sign Up
                </NavLinks>
              </NavItem>,
              <NavItem>
              <NavLinks
                to='/login'
                duration={500}
                exact='true'
                offset={-80}
                // scrollnav={ scrollnav ? 1 : 0 }
              >
                Login
              </NavLinks>
            </NavItem>]
            )}
            
          </NavMenu>

        </NavbarContainer>
      </NavGradient>
    </Nav>
  )
}

Navbar.defaultProps = { 
  currentUser: null
}

export default Navbar;

// import React, {
//     useState,
//     useEffect }                       from 'react';
  
//   import { IconContext }              from 'react-icons/lib';
//   import { animateScroll as scroll }  from 'react-scroll';
// import LuckeyLivingLogo from '../../assets/images/luckey-living-logo-0001.png';

//   import {
//     MobileIcon,
//     Nav,
//     NavbarContainer,
//     NavItem,
//     NavLinks,
//     NavLogo,
//     NavLogoImage,
//     NavMenu,
//     NavBtn,
//     NavBtnLink,
//     NavGradient,
//     Hamburger }                       from './Navbar.elements';
// //   import { signOutUserStart }         from '../../redux/User/user.actions';
  
// //   const mapStateToProps = ({ user }) => ({
// //     currentUser: user.currentUser
// //   });
  
//   const Navbar = ({ toggle }) => {
//     // const dispatch                  = useDispatch();
//     // const {currentUser}             = useSelector(mapStateToProps);
//     const [click, setClick]         = useState(false);
//     // const signOut                   = () => {
//     //   dispatch(signOutUserStart());
//     // };
  
//     const handleClick               = () => setClick(!click);
//     const closeMobileMenu           = () => setClick(false);
  
//     const [scrollnav, setScrollNav] = useState(false);
  
//     const changeNav = () => {
//       if (window.scrollY >= 80) {
//         setScrollNav(true);
//       } else {
//         setScrollNav(false);
//       }
//     };
  
//     useEffect(() => {
//       window.addEventListener('scroll', changeNav);
//     }, []);
  
//     const toggleHome = () => {
//       scroll.scrollToTop();
//     };
  
//     return (
//       <>
//         <IconContext.Provider value={{ color: '#fff' }}>
//           <Nav scrollnav={ scrollnav ? 1 : 0 }>
//             <NavGradient scrollnav={ scrollnav ? 1 : 0 }>
//             <NavbarContainer>
//               <NavLogo onClick={toggleHome} to='/'>
//                 <NavLogoImage src={LuckeyLivingLogo} />
//               </NavLogo>
//               <MobileIcon onClick={toggle} scrollnav={ scrollnav ? 1 : 0 }>
//                 <Hamburger scrollnav={ scrollnav ? 1 : 0 } color='#1993356' />
//               </MobileIcon>
//               <NavMenu>
//                 <NavItem>
//                   <NavLinks
//                     to='about'
//                     smooth={true}
//                     duration={500}
//                     spy={true}
//                     exact='true'
//                     offset={-80}
//                     scrollnav={ scrollnav ? 1 : 0 }
//                   >
//                     About
//                   </NavLinks>
//                 </NavItem>
//                 <NavItem>
//                   <NavLinks
//                     to='discover'
//                     smooth={true}
//                     duration={500}
//                     spy={true}
//                     exact='true'
//                     offset={-80}
//                     scrollnav={ scrollnav ? 1 : 0 }
//                   >
//                     Discover
//                   </NavLinks>
//                 </NavItem>
//                 <NavItem>
//                   <NavLinks
//                     to='services'
//                     smooth={true}
//                     duration={500}
//                     spy={true}
//                     exact='true'
//                     offset={-80}
//                     scrollnav={ scrollnav ? 1 : 0 }
//                   >
//                     Services
//                   </NavLinks>
//                 </NavItem>
//                 <NavItem>
//                   <NavLinks
//                     to='store'
//                     smooth={true}
//                     duration={500}
//                     spy={true}
//                     exact='true'
//                     offset={-80}
//                     scrollnav={ scrollnav ? 1 : 0 }
//                   >
//                     Store
//                   </NavLinks>
//                 </NavItem>
//                 <NavItem>
//                   <NavLinks
//                     to='signup'
//                     smooth={true}
//                     duration={500}
//                     spy={true}
//                     exact='true'
//                     offset={-80}
//                     scrollnav={ scrollnav ? 1 : 0 }
//                   >
//                     Sign Up
//                   </NavLinks>
//                 </NavItem>
//               </NavMenu>
//               {/* {currentUser && ( */}
//               {false && (
//                 <>
//                   <NavBtn>
//                     <NavBtnLink to='/dashboard'> Dashboard </NavBtnLink>
//                   </NavBtn>
//                 </>
//               )}
  
//               {/* {!currentUser && ( */}
//             {true && (

//                 <>
//                   <NavBtn>
//                     <NavBtnLink to='/dashboard'> Sign In </NavBtnLink>
//                   </NavBtn>
//                 </>
//               )}
              
//             </NavbarContainer>
//             </NavGradient>
//           </Nav>
//         </IconContext.Provider>
//       </>
//     );
//   };
  
//   export default Navbar//connect(mapStateToProps, null)(Navbar);
