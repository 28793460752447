import React from 'react';
import {
    FormRow,
    Label,
    Select } from './index.elements';

const FormSelect = ({ options, defaultValue, handleChange, label, ...otherProps }) => {
  if (!Array.isArray(options) || options.length < 1) return null;

  return (
    <FormRow>
      {label && (
        <Label>
          {label}
        </Label>
      )}

      <Select value={defaultValue} onChange={handleChange} {...otherProps}>
        {options.map((option, index) => {
          const { value, name } = option;

          return (
            <option key={index} value={value}>{name}</option>
          );
        })}
      </Select>
    </FormRow>
  );
}

export default FormSelect;