import styled               from "styled-components";
import FormInput            from "./../forms/FormInput";
import FormCountrySelector  from "../forms/CountrySelector";
import { Link }             from "react-router-dom";

export const PaymentDetailsDiv = styled.div`
    display     : block;
    width       : 100%;
    max-width   : 100.0rem;
    padding     : 0;
    margin      : 2rem auto;
`;

export const PaymentForm = styled.form`

`;

export const GroupDiv = styled.div`
    margin      : 0 0 2rem;
`;

export const GroupH2 = styled.h2`

`;

export const GroupH3 = styled.h3`

`;

export const PaymentFormInput = styled(FormInput)`

`;

export const CountrySelector = styled(FormCountrySelector)`

`;