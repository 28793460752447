import React, { useEffect }     from 'react';
import {
  useDispatch,
  useSelector }                 from 'react-redux';
import { getUserOrderHistory }  from './../../redux/Orders/orders.sagas';
import { Header }               from './index.elements'

const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const Dashboard = props => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);

  useEffect(() => {
    // dispatch(
    //   getUserOrderHistory(currentUser.id)
    // );
  }, []);

  return (
    <Header>You are logged in</Header>
  );
};

export default Dashboard;
