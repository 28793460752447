import styled from "styled-components";

export const ModalOverlay = styled.div`
    position            : fixed;
    top                 : 0;
    left                : 0;
    width               : 100%;
    height              : 100%;
    background-color    : rgba(0,0,0,.3);
`;

export const ModalWrap = styled.div`
    position            : fixed;
    top                 : 0; left: 0;
    width               : 100%;
    height              : 100%;
    pointer-events      : none;
    z-index             : 100;
`;

export const ModalDiv = styled.div`
    position            : absolute;
    top                 : 50%;
    left                : 50%;
    -webkit-transform   : translate(-50%, -50%);
    -moz-transform      : translate(-50%, -50%);
    transform           : translate(-50%, -50%);
    background-color    : white;
    width               : 95%;
    padding             : 1rem;
    max-width           : 60rem;
    height              : auto;
    min-height          : 40.0rem;
    pointer-events      : all;
    max-height          : 95vh;
    overflow            : auto;
`;