import styled   from 'styled-components';
import Button   from '../forms/Button';
import { Link } from 'react-router-dom';


export const ProductsDiv = styled.div`
    display     : block;
    width       : 100%;
    padding     : 0 3rem;
    margin      : 2rem 0;
`;

export const Results = styled.div`
display                 : grid;
grid-template-columns   : repeat(3, 1fr);
grid-template-rows      : repeat(2, minmax(200px, auto));
/* grid-template-rows      : repeat(2, minmax(auto, 100%)); */
grid-gap                : 0.1rem;
/* align-items             : end; */
/* justify-content         : center; */
/* align-content: end; */
margin                  : 3rem -1rem 0;
`;

export const Header = styled.h1`

`;

// export const Results = styled.div`
//     display     : flex;
//     flex-wrap   : wrap;
//     margin      : 3rem -1rem 0;
// `;

export const ProductDiv = styled.div`
    display         : flex;
    flex-direction: column;
    align-items     : end;
    justify-content: space-between;
    /* justify-content : center; */
    /* width           : 33.3333333333333333%; */
    /* height      : 40.0rem; */
    margin      : 0 auto 2rem;
    padding     : 0 10px;
    
    /* float       : left; */
`;

export const ThumbnailDiv = styled.div`
    display     : block;
    width       : 100%;
    height      : 100%;
    `;

export const Thumbnail = styled.img`
    display     : block;
    max-width   : 100%;
    max-height  : 30rem;
    margin      : 0;
    width       : auto;
    height      : auto;
    object-fit  : contain;
`;

export const DetailsDiv = styled.div`
    display     : block;
    width       : 100%;
    margin      : 0 auto;
    padding     : 1rem 0;
    

    ul, ul li {
            margin  : 0;
            padding : 0;
        }

        ul {
            li {
                list-style-type : none;
                display         : block;
                width           : 100%;
                text-align      : left;
                margin          : 0 0 0.5rem;
            }
        }
`;

export const Name = styled.span`
    font-size   : 2.2rem;
    line-height : 1.2;
    font-weight : 400;
`;

export const Price = styled.span`
    font-size   : 1.6rem;
    line-height : 1;
    font-weight : 400;
`;

export const AddToCartButton = styled(Button)`
    /* width: 53%; */
    width: 100%;
    height: 100%;
    max-width: 200px;
    float: left;
`;

export const AddToCartDiv = styled.div`
    margin: 2rem 0 0;
    width: 100%;
    height: 100%;
`;

export const ProductLink = styled(Link)`
    color: black;
`;