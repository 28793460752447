import React from 'react';
import { useDispatch } from 'react-redux';
import { addProduct, reduceCartItem, removeCartItem } from '../../../redux/Cart/cart.actions';
import {
  PlusSquare,
  MinusSquare,
  CloseSquare,
  CartItemTable,
  CartBtn,
  CartBtnIcon,
  CartImg,
  CartQty,
  CartRemove } from '../index.elements';


const Item = (product) => {
    const dispatch = useDispatch();
    const {
        productName,
        productThumbnail,
        productPrice,
        qty,
        documentID
    } = product;

    const handleRemoveCartItem = (documentID) => {
        dispatch(
            removeCartItem({
                documentID
            })
        );
    };

    const handleAddProduct = (product) => {
        dispatch(
            addProduct(product)
        );
    };

    const handleReduceItem = (product) => {
        dispatch(
            reduceCartItem(product)
        );
    };

    return (
        <CartItemTable>
            <tbody>
                <tr>
                    <td>
                        <CartImg src={productThumbnail} alt={productName} />
                    </td>
                    <td>{productName}</td>
                    <td>
                        <CartBtn onClick={() => handleReduceItem(product)}>
                            <MinusSquare className="cartBtnIcon" size={25} />
                        </CartBtn>
                        <CartQty>
                          {qty}
                        </CartQty>
                        <CartBtn onClick={() => handleAddProduct(product)}>
                            <PlusSquare className="cartBtnIcon" size={25} />
                        </CartBtn>
                    </td>
                    <td>${productPrice}</td>
                    <td align="center">
                        <CartRemove onClick={() => handleRemoveCartItem(documentID)}>
                            <CloseSquare className="cartBtnIcon" size={25} />
                        </CartRemove>
                    </td>
                </tr>
            </tbody>
        </CartItemTable>
    );
};

export default Item;




//****************************************************************** */


// import React              from 'react'
// import { useDispatch }    from 'react-redux';
// import { addProduct, reduceCartItem, removeCartItem } from '../../../redux/Cart/cart.actions';
// import { 
//     PlusSquare,
//     MinusSquare,
//     CloseSquare }         from '../index.elements';
// import './../styles.scss'

// const Item = (product) => {

//     const dispatch = useDispatch();

//     const {
//         productName,
//         productThumbnail,
//         productPrice,
//         qty,
//         documentID
//     } = product;

//     const handleRemoveCartItem = (documentID) => {
//         dispatch(
//           removeCartItem({
//             documentID
//           })
//         );
//       }
    
//       const handleAddProduct = (product) => {
//         dispatch(
//           addProduct(product)
//         )
//       }
    
//       const handleReduceItem = (product) => {
//         dispatch(
//           reduceCartItem(product)
//         );
//       }

//   return (
//     <table className='cartItem' border='0' cellSpacing='0' cellPadding='10' >
//         <tbody>
//         <tr>
//           <td>
//             <img src={productThumbnail} alt={productName} />
//           </td>
//           <td>
//             {productName}
//           </td>
//           <td>
//             <span className="cartBtn"
//               onClick={() => handleReduceItem(product)}
//               >
//                 <MinusSquare className='cartBtnIcon' size={25} />
//             </span>
//             <span>
//               {qty}
//             </span>
//             <span className="cartBtn"
//               onClick={() => handleAddProduct(product)}
//               >
//                 <PlusSquare className='cartBtnIcon' size={25} />
//             </span>
//           </td>
//           <td>
//             ${productPrice}
//           </td>
//           <td align="center">
//             <span className="cartBtn remove"
//             onClick={() => handleRemoveCartItem(documentID)}
//             >
//               <CloseSquare className='cartBtnIcon' size={25} />
//             </span>
//           </td>
//         </tr>
//       </tbody>
//     </table>
//   );
// }

// export default Item;