const vCard = `BEGIN:VCARD
VERSION:4.0
N:Brown;Sara
FN:Sara Brown
ORG:Luckey Living LLC
TEL;TYPE=cell,voice;VALUE=uri:tel:+1-269-967-5439
ADR;TYPE=WORK:;;P.O. Box 466;Luckey;Ohio;43443;USA
URL:https://www.luckeyliving.com
END:VCARD`;

export default vCard;