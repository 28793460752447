import React          from 'react';
import ContactProfile from '../../components/ContactProfile';
import { sampleUser } from '../../components/ContactProfile/sampleUser';
// import Contact from '../../components/ContactUs';
function ContactPage() {

  return (
    <>
      <ContactProfile user={sampleUser} />
    </>
  );
}

export default ContactPage;