import React    from 'react';
import Footer   from '../components/Footer';
import Navbar   from './../components/Navbar';

const HomepageLayout = props => {
    return (
        <div className='fullHeight'>
            <Navbar {...props} />
            <div>
                {props.children}
            </div>
            <Footer />
        </div>
    );
};

export default HomepageLayout