import React                    from 'react'
import {
    FormLabel,
    FormRow,
    FormCountrySelectorView }   from './index.elements'

const FormCountrySelector = ({ handleChange, label, valueType, ...otherProps }) => {
  return (
    <FormRow>
        {label && (
            <FormLabel>
                {label}
            </FormLabel>
        )}

        <FormCountrySelectorView onChange={handleChange} valueType={valueType} value="United States" {...otherProps} />
    </FormRow>
  )
}

export default FormCountrySelector