import React            from 'react';
import { useDispatch }  from 'react-redux';
import { addProduct }   from './../../../redux/Cart/cart.actions';
import { useNavigate }  from 'react-router';
import {
    AddToCartDiv,
    AddToCartButton,
    DetailsDiv,
    Name,
    Price,
    ProductDiv,
    Thumbnail,
    ThumbnailDiv, 
    ProductLink }       from '../index.elements';

const Product = (product) => {
    const dispatch          = useDispatch();

    const navigate          = useNavigate();
    const {
        documentID,
        productThumbnail,
        productName,
        productPrice }      = product;
    if(!documentID || !productThumbnail || !productName ||
        typeof productPrice === 'undefined') return null;

    const configAddToCartButton = {
        type: 'button'

    };

    const handleAddToCart = (product) => {
        if (!product) return;
        dispatch(
            addProduct(product)
        )
        // navigate('/cart');
    };

  return (
    <ProductDiv>
        <ThumbnailDiv>
            <ProductLink to={`/product/${documentID}`}>
                <Thumbnail src={productThumbnail} alt={productName}/>
            </ProductLink>
        </ThumbnailDiv>
    
        <DetailsDiv>
            <ul>
                <li>
                    <Name>
                        <ProductLink to={`/product/${documentID}`}>
                            {productName}
                        </ProductLink>
                    </Name>
                </li>
            </ul>
            <ul>
                <li>
                    <Price>
                        <ProductLink to={`/product/${documentID}`}>
                            ${productPrice}
                        </ProductLink>
                    </Price>
                </li>
                <li>
                    <AddToCartDiv>
                        <AddToCartButton {...configAddToCartButton} onClick={() => handleAddToCart(product)} >
                            Add To Cart
                        </AddToCartButton>
                    </AddToCartDiv>
                </li>
            </ul>
        </DetailsDiv>
    </ProductDiv>
  )
}

export default Product