import React, {
    useState,
    useEffect }                         from 'react';

import {
    useDispatch,
    useSelector }                       from 'react-redux';

import AuthWrapper                      from '../AuthWrapper';
import FormInput                        from '../forms/FormInput';
import Button                           from '../forms/Button';
import {
    Form,
    FormWrap }                          from './index.elements';

import {
    resetPasswordStart,
    resetUserState }                    from '../../redux/User/user.actions';
    
import { useNavigate }                  from "react-router-dom";

  const mapState = ({ user }) => ({
    resetPasswordSuccess    : user.resetPasswordSuccess,
    userErr                 : user.userErr
  });

const EmailPassword = props => {
    const dispatch                                      = useDispatch();
    const navigate                                      = useNavigate();
    const { resetPasswordSuccess, userErr }             = useSelector(mapState);
    const [email,   setEmail    ]                       = useState('');
    const [errors,  setErrors   ]                       = useState('');

    useEffect(() => {
        if (resetPasswordSuccess) {
            dispatch(resetUserState());
            navigate('/login')
        }
    }, [resetPasswordSuccess]);

    useEffect(() => {
        if (Array.isArray(userErr) && userErr.length > 0 ) {
            setErrors(userErr);
        }
    }, [userErr]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(resetPasswordStart({ email }));

    }

        const configAuthWrapper = {
            headline      : "Email Password",
            destination1  : "Remember password?",
            href1         : "/login",
            destination2  : "Don't have an Account?",
            href2         : "/signup"
          }
        return (
            <AuthWrapper {...configAuthWrapper}>
                <FormWrap>
                    {errors.length > 0 && (
                        <ul>
                            {errors.map((e, index) => {
                                return (
                                    <li key={index}>
                                        {e}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <FormInput
                            type        ="email"
                            name        ="email"
                            value       ={email}
                            placeholder ="Email"
                            handleChange={e => setEmail(e.target.value)}
                        />
                        <Button type="submit">Email Password</Button>
                    </Form>
                </FormWrap>
            </AuthWrapper>
          )
    }


export default EmailPassword;