import                            './default.scss';
import React, { useEffect }     from  'react';
import { useDispatch }          from  'react-redux';
import { 
  Route,
  Routes }                      from  'react-router-dom';

import { checkUserSession }     from  './redux/User/user.actions';


// // HOC
import WithAuth                 from  './hoc/withAuth';
import WithAdminAuth            from  './hoc/withAdminAuth';

// layouts
import MainLayout               from  './layouts/MainLayout';
import HomepageLayout           from  './layouts/HomepageLayout';
import LimitedLayout            from  './layouts/LimitedLayout';
import AdminLayout              from  './layouts/AdminLayout';
import DashboardLayout          from  './layouts/DashboardLayout';

// pages
import Homepage                 from  './page/Homepage';
import Registration             from  './page/RegistrationPage';
import Login                    from  './page/LoginPage';
import StorePage                from  './page/Store';
import PageNotFoundPage         from  './page/PageNotReadyPage';
import Recovery                 from  './page/Recovery';
import Dashboard                from  './page/Dashboard';
import Admin                    from  './page/Admin';
import Search                   from './page/Search';
import DirectoryLayout          from './layouts/DirectoryLayout';
import ProductDetails           from './page/ProductDetails';
import Cart                     from './page/Cart';
import PaymentPage              from './page/Payment';
import PaymentConfirmationPage  from './page/PaymentConfirmationPage';
import ContactPage from './page/Contact';

const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());

  }, []);


    return (
      <div className="App">
        {/* <AdminToolbar /> */}
        <Routes>
          <Route path='/'                   element={
          <HomepageLayout >
            <Homepage /> 
          </HomepageLayout>
        } />

          <Route path='/contact'             element={ 
            <MainLayout >
              <ContactPage />
            </MainLayout>
            } />
  
          <Route path='/signup'             element={ 
            <MainLayout >
              <Registration />
            </MainLayout>
            } />
  
          <Route path='/login'              
          
            element={       
              <MainLayout >
                <Login />
              </MainLayout>
            } />
    
          <Route path='/recovery'              
            element={       
              <MainLayout >
                <Recovery />
              </MainLayout>
          } />
    
          <Route path='/admin'              
            element={
              <WithAdminAuth>
                <AdminLayout >
                  <Admin />
                </AdminLayout>
              </WithAdminAuth>
              
          } />

          <Route path='/dashboard'              
            element={       
              <WithAuth>
                <DashboardLayout >
                  <Dashboard />
                </DashboardLayout>
              </WithAuth>
              
          } />

          <Route exact path='/search'       element={
            <MainLayout>
              <Search />
            </MainLayout>
          } />

          <Route path='/search/:filterType' element={
            <MainLayout>
              <Search />
            </MainLayout>
          } />

          <Route path='/product/:productID' element={
            <MainLayout>
              <ProductDetails />
            </MainLayout>
          } />

          <Route path='/store'              element={
            <MainLayout >
              <Search />
            </MainLayout>
          } />

          <Route path='/cart'              element={
            <MainLayout >
              <Cart />
            </MainLayout>
          } />

          <Route path='/payment'              element={
            <WithAuth>
              <MainLayout >
                <PaymentPage />
              </MainLayout>
            </WithAuth>
          } />
  
{/*           <Route path='/store/commercial'   element={
            <MainLayout >
              <h1>Commercial Store</h1>
            </MainLayout>
          } />
  
          <Route path='/store/residential'  element={
            <MainLayout >
              <h1>Residential Store</h1> 
            </MainLayout>
          } /> */}
          <Route        path='confirmation' element={
            <LimitedLayout label="Home" destination="/"  >
              <PaymentConfirmationPage />
            </LimitedLayout>
          } />

          <Route        path='*'            element={
            <LimitedLayout label="Home" destination="/"  >
              <PageNotFoundPage />
            </LimitedLayout>
          } />
        </Routes>
      </div>
    );
  };

export default App;
