import styled from 'styled-components';

export const ProductCardDiv = styled.div`
    max-width: 80.0rem;
    margin: 0 auto 10.0rem;
`;

export const HeroDiv            = styled.div`
    display: block;
    width: 100%;
    margin: 2rem auto;
`;

export const HeroImage          = styled.img`
    display: block;
    width: 100%;
    margin: 0;
`;

export const ProductDetails     = styled.div`
    
    ul, ul li {
        padding: 0;
        margin: 0;
    }

    ul li {
        list-style-type: none;
        margin: 0 auto 1rem;
    }
`;

export const ProductName        = styled.h1`
    margin: 0;
`;

export const ProductPrice       = styled.span`

`;

export const AddToCart          = styled.div`
    max-width: 20.0rem;
`;

export const ProductDescription = styled.span`
    
`;