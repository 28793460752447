import styled from "styled-components";
import Button from "../../components/forms/Button";

export const AdminPage = styled.h1`
    padding: 0 10px;
`;

export const CallToActions = styled.div`
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0 auto;

    ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    ul {
        li {
            display: inline-block;
        }
    }
`;

export const AddNewProductFormDiv = styled.div`

`;

export const AddNewProductForm = styled.form`

`;

export const ManageProducts = styled.div`
    h1 {
        margin-top: 1.5rem;
    }

    table.results {
        tr {
            &:nth-child(even) {
                background-color: #d3d3d3;
            }

            .thumb {
                width: 15.0rem;
                margin: 0 auto;
            }
            
        }
    }
`;

export const TableButton = styled(Button)`
    /* margin: 4px;
    border-radius: 8px; */
`;

// export const thumb = styled.image`
// width: 15.0rem;
// margin: 0 auto;
// `;

