import firebase                 from    'firebase/compat/app';
import                                  'firebase/compat/firestore';
import {
    // collection,
    // addDoc,
    doc,
    setDoc }                    from    'firebase/firestore';
import                                  'firebase/compat/auth';
import { firebaseConfig }       from    './config';

import Prospect, { prospectConverter } from '../model/Prospect';

firebase.initializeApp(firebaseConfig);

export const auth               = firebase.auth();
export const firestore          = firebase.firestore();

export const GoogleProvider            = new firebase.auth.GoogleAuthProvider();
const FacebookProvider          = new firebase.auth.FacebookAuthProvider();

GoogleProvider.setCustomParameters({    prompt: 'select_account' });
FacebookProvider.setCustomParameters({  prompt: 'select_account' });

// export const handleUserProfile  =  async (userAuth, additionalData) => {
//     if (!userAuth) return;
//     const { uid }   = userAuth;
//     const userRef   = firestore.doc(`Users/${uid}`);
//     const snapshot  = await userRef.get();
//     if (!snapshot.exists) {
//         const { displayName, email } = userAuth;
//         const timestamp = new Date();
//         try {
//             await userRef.set({
//                 displayName,
//                 email,
//                 dateCreated: timestamp,
//                 dateUpdated: timestamp,
//                 ...additionalData
//             });
//         } catch(err) {
//             console.log(err);
//         }
//     }
// }

export const ProviderOptionSet = { 
    'password'      : 1 << 0,
    'phone'         : 1 << 1,
    'google.com'    : 1 << 2,
    'apple.com'     : 1 << 3,
    'yahoo.com'     : 1 << 4,
    'facebook.com'  : 1 << 5,
    'twitter.com'   : 1 << 6,
    'microsoft.com' : 1 << 7
 }


/**
    * Renders a thing of a component
    * @param {Object}                               props
    * @param {firebase.auth.UserCredential} props.userAuth - the color of the text in the button
    * @param {Object}                               props.additionalData - the background color of the button
*/

export const handleUserProfile = async ({ userAuth, additionalData }) => {
    // export const handleUserProfile = async ({ userAuth, additionalData }) => {

    console.log(`Called handleUserProfile. !userAuth = ${!userAuth}`);
    if (!userAuth) return;

    var providerOptionSet = userAuth.providerData.map(({ providerId }) => {
        return ProviderOptionSet[`${providerId}`]
    }).reduce((a, b) => a | b, 0);


    const { uid }   = userAuth;

    const userRef   = firestore.doc(`Users/${uid}`);
    const snap      = await userRef.get();

    if (!snap.exists) {
        console.log(`Inside !snap.exists\nadditionalData: ${additionalData}`);
        const newUsersRef   = firestore.doc(`NewUsers/${uid}`);
        const newSnap       = await newUsersRef.get();
        if (!newSnap.exists) {
            console.log('Inside !newSnap.exists');
            const { uid, email, providerData, photoURL } = userAuth;
            var { displayName } = userAuth;
            if (displayName === null) {
                if (additionalData !== undefined) {
                    displayName = additionalData.displayName
                }                
            }
            // const onboardingLevel   = 1;
            const timestamp         = new Date();
            const userRoles      = ['newUser', 'onboarding']

            const accessOptionSet   = 0;
            console.log(`inside handleUserProfile > newSnap does not exist\n`)
            const prospect = new Prospect(
                uid,
                displayName,
                email,
                photoURL,
                accessOptionSet,
                providerOptionSet,
                providerData,
                userRoles,
                timestamp,
                timestamp
            )

            try {

                const ref = doc(firestore, 'NewUsers', uid).withConverter(prospectConverter);
                await setDoc(ref, prospect);



                // await testRef.set({
                //     uid,
                //     displayName,
                //     email,
                //     photoURL,
                //     providerOptionSet,
                //     providerData,
                //     dateCreated: timestamp,
                //     dateUpdated: timestamp,
                //     accessOptionSet,
                //     ...additionalData
                // })
            } catch(err) {
                console.log("Error Logging In:", err)
            }
        } else if (providerOptionSet !== newSnap.data().providerOptionSet) {
            const { providerData } = userAuth;
            const timestamp = new Date();
    
            console.log("New Provider", providerOptionSet, newSnap.data().providerOptionSet)
    
            try {
                await newUsersRef.update({
                    providerOptionSet,
                    providerData,
                    dateUpdated: timestamp,
                    ...additionalData
                })
            } catch(err) {
                console.log(err)
            }
        } else {
            console.log("Provider Matches", providerOptionSet, newSnap.data().providerOptionSet)
        }

        return newUsersRef;

    } else if (providerOptionSet !== snap.data().providerOptionSet) {
        const { providerData } = userAuth;
        const timestamp = new Date();

        console.log("New Provider", providerOptionSet, snap.data().providerOptionSet)

        try {
            await userRef.update({
                providerOptionSet,
                providerData,
                dateUpdated: timestamp,
                ...additionalData
            })
        } catch(err) {
            console.log(err)
        }

        return userRef;
    } else {
        console.log("Provider Matches", providerOptionSet, snap.data().providerOptionSet)
    return userRef;
    }
}

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject);
    })
}