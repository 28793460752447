import React, {
  useState,
  useEffect }                       from 'react';
import { apiInstance }              from '../../Utils';
import {
  selectCartTotal,
  selectCartItemsCount,
  selectCartItems }                 from './../../redux/Cart/cart.selectors';
import { saveOrderHistory }         from '../../redux/Orders/orders.actions';
import { createStructuredSelector } from 'reselect';
import {
  useSelector,
  useDispatch }                     from 'react-redux';
import {
  CardElement,
  useStripe,
  useElements }                     from '@stripe/react-stripe-js';
import {
  GroupDiv,
  GroupH2,
  PaymentDetailsDiv,
  PaymentForm,
  PaymentFormInput,
  CountrySelector,
  GroupH3}                          from './index.elements';
  import Button                     from './../forms/Button';
import { HStack }                   from '../SwiftUIComponents';
import { useNavigate }              from 'react-router';

const initAddressState = {
  line1       : '',
  line2       : '',
  city        : '',
  state       : '',
  postal_code : '',
  country     : 'United States'
}

const mapState = createStructuredSelector({
  total     :  selectCartTotal,
  itemCount : selectCartItemsCount,
  cartItems : selectCartItems
});

const PaymentDetails = () => {
  
  const navigate                                  =useNavigate();
  const stripe                                    = useStripe();
  const elements                                  = useElements();
  const { total, itemCount, cartItems }           = useSelector(mapState);
  const dispatch                                  = useDispatch();
  const [ billingAddress,   setBillingAddress   ] = useState({...initAddressState});
  const [ shippingAddress,  setShippingAddress  ] = useState({...initAddressState});
  const [ recipientName,    setRecipientName    ] = useState('');
  const [ nameOnCard,       setNameOnCard       ] = useState('');

  useEffect(() => {
    if (itemCount < 1 ) {
      navigate('/dashboard');
    }
  }, [itemCount]);

  const handleShipping  = event => {
    const { name, value } = event.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value
    });
  };

  const handleBilling   = event => {
    const { name, value } = event.target;
    setBillingAddress({
      ...billingAddress,
      [name]: value
    });
  };

  const handleFormSubmit = async event => {
    event.preventDefault();

    const cardElement = elements.getElement('card');

    if (
      !recipientName                || !nameOnCard                 ||
      !shippingAddress.line1        || !billingAddress.line1       ||
      !shippingAddress.city         || !billingAddress.city        ||
      !shippingAddress.state        || !billingAddress.state       ||
      !shippingAddress.postal_code  || !billingAddress.postal_code ||
      !shippingAddress.country      || !billingAddress.country     
      ) {
        return;
      }

      apiInstance.post('/payments/create', {
        amount: total * 100,
        shipping: {
          name:recipientName,
          address: {
            ...shippingAddress
          }
        }
      }).then(({ data: clientSecret }) => {

        stripe.createPaymentMethod({
          type            :'card',
          card            : cardElement,
          billing_details : {
            name    : nameOnCard,
            address : {
              ...billingAddress
            }
          }
        }).then(({ paymentMethod }) => {

          stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id
          })
          .then(({ paymentIntent }) => {
            
            const configOrder = {
              orderTotal  : total,
              orderItems  : cartItems.map(item => {
                const {
                  documentID,
                  productThumbnail,
                  productName,
                  productPrice,
                  qty } = item;
                return {
                  documentID,
                  productThumbnail,
                  productName,
                  productPrice,
                  qty
                };
              })
            }

            dispatch(
              saveOrderHistory(configOrder)
            );
          });

        })
      });
  };

  const configCardElement = {
    iconStyle: 'solid',
    style: {
      base: {
        fontSize: '16px'
      }
    },
    hidePostalCode: true
  };

  return (
    <PaymentDetailsDiv>
      <PaymentForm onSubmit={handleFormSubmit}>

      <GroupDiv>
          <GroupH2>
            Shipping Address
          </GroupH2>

          <PaymentFormInput
            required
            placeholder   ="Recipient Name"
            name          ="recipientName"
            handleChange  ={event => setRecipientName(event.target.value)}
            value         ={recipientName}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="Line 1"
            name          ="line1"
            handleChange  ={event => handleShipping(event)}
            value         ={shippingAddress.line1}
            type          ="text"
            />

          <PaymentFormInput
            placeholder   ="Line 2"
            name          ="line2"
            handleChange  ={event => handleShipping(event)}
            value         ={shippingAddress.line2}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="City"
            name          ="city"
            handleChange  ={event => handleShipping(event)}
            value         ={shippingAddress.city}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="State"
            name          ="state"
            handleChange  ={event => handleShipping(event)}
            value         ={shippingAddress.state}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="Postal Code"
            name          ="postal_code"
            handleChange  ={event => handleShipping(event)}
            value         ={shippingAddress.postal_code}
            type          ="text"
            />

          <CountrySelector
            required
            valueType     ="short"
            value         ={shippingAddress.country}
            handleChange  ={value => handleShipping({
              target: {
                name: 'country',
                value: value
              }
            })}
          />

        </GroupDiv>

        <GroupDiv>
          <GroupH2>
            Billing Address
          </GroupH2>

          <PaymentFormInput
            required
            placeholder   ="Name On Card"
            name          ="nameOnCard"
            handleChange  ={event => setNameOnCard(event.target.value)}
            value         ={nameOnCard}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="Line 1"
            name          ="line1"
            handleChange  ={event => handleBilling(event)}
            value         ={billingAddress.line1}
            type          ="text"
            />

          <PaymentFormInput
            placeholder   ="Line 2"
            name          ="line2"
            handleChange  ={event => handleBilling(event)}
            value         ={billingAddress.line2}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="City"
            name          ="city"
            handleChange  ={event => handleBilling(event)}
            value         ={billingAddress.city}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="State"
            name          ="state"
            handleChange  ={event => handleBilling(event)}
            value         ={billingAddress.state}
            type          ="text"
            />

          <PaymentFormInput
            required
            placeholder   ="Postal Code"
            name          ="postal_code"
            handleChange  ={event => handleBilling(event)}
            value         ={billingAddress.postal_code}
            type          ="text"
            />

          <CountrySelector
            required
            valueType     ="short"
            value         ={billingAddress.country}
            handleChange  ={value => handleBilling({
              target: {
                name: 'country',
                value: value
              }
            })}
          />

        </GroupDiv>

        <GroupDiv>
          <GroupH2>
            Card Details
          </GroupH2>

          <CardElement
            options={configCardElement}
          />
        </GroupDiv>

        <GroupDiv>
          <GroupH3>
            Total: ${total}
          </GroupH3>
        </GroupDiv>

        <GroupDiv>
          <HStack>
            <Button type="submit">
              Pay Now
            </Button>
            <Button onClick={() => navigate('/cart')}>
              Return To Cart
            </Button>
          </HStack>
        </GroupDiv>

      </PaymentForm>
    </PaymentDetailsDiv>
  )
}

export default PaymentDetails