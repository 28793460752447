import { createSelector } from 'reselect';

export const selectCartData         = state => state.cartData;
export const selectCartItems        = createSelector(
  [selectCartData],
  cartData => cartData.cartItems
);

export const selectCartItemsCount   = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (qty, cartItem) =>
        qty + cartItem.qty
      , 0)
);

export const selectCartTotal = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (qty, cartItem) =>
        qty + cartItem.qty * cartItem.productPrice,
    0).toFixed(2)
);

export const selectCartTax = createSelector(
  [selectCartItems],
  cartItems =>
    (cartItems.reduce(
      (qty, cartItem) =>
        qty + cartItem.qty * cartItem.productPrice,
    0) * 0.0725).toFixed(2)
);