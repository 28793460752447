import React from 'react';
import userIMG from './../../assets/images/user.png';
import { UserProfileDiv, UserProfileImg, UserProfileImgDiv, UserProfileLI, UserProfileUL } from './index.elements';

const UserProfile = props => {
  const { currentUser } = props;
  const { displayName } = currentUser;

  return (
    <UserProfileDiv>
      <UserProfileUL>
        <UserProfileLI>
          <UserProfileImgDiv>
            <UserProfileImg src={userIMG} />
          </UserProfileImgDiv>
        </UserProfileLI>
        <UserProfileLI>
          <span className="displayName">
            {displayName && displayName}
          </span>
        </UserProfileLI>
      </UserProfileUL>
    </UserProfileDiv>
  );
}

export default UserProfile;