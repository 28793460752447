import styled               from 'styled-components';
import { Link as LinkR }    from 'react-router-dom';
import { Link as LinkS }    from 'react-scroll';
import { FaBars, FaShoppingCart }           from 'react-icons/fa';

// export const Nav = styled.nav`
//     height: 6.5rem;
//     box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
// `

export const Nav = styled.nav`
    background      : #000;
    height          : 6.5rem;
    /* margin-top      : -80px; */
    display         : flex;
    justify-content : center;
    align-items     : center;
    font-size       : 1rem;
    position        : sticky;
    top             : 0;
    z-index         : 10;

    @media screen and (max-width: 960px) {
        transition  : 0.8s all ease;
    }
`;

export const NavGradient = styled.nav`
  /* background      : ${({ scrollnav }) => (scrollnav ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)')}; */
  height          : 6.5rem;
  width           : 100%;
  margin-top      : -6.5rem;
  display         : flex;
  position        : sticky;
  top             : 0;
  z-index         : 9;

  @media screen and (max-width: 1400px) {
    transition    : 0.8s all ease-in-out;
  }
`;

export const NavbarContainer = styled.div`
    display         : flex;
    justify-content : space-between;
    position        : relative;
    height          : 6.5rem;
    z-index         : 1;
    width           : 100%;
    padding         : 0 24px;
    max-width       : 1100px;
`;

export const NavLogo = styled(LinkR)`
    color           : #fff;
    justify-self    : flex-start;
    cursor          : pointer;
    font-family     : 'Lobster';
    font-size       : 1.5rem;
    display         : flex;
    align-items     : center;
    margin-left     : 6px;
    font-weight     : bold;
    text-decoration : none;
`;


export const NavLogoImage = styled.img`
    display             : block;
    position            : absolute;
    height              : 100%;
    margin-bottom       : 10px;
`;

export const Hamburger = styled(FaBars)`
    color             : ${({ scrollnav }) => (scrollnav ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)')};
`;

export const ShoppingCart = styled(FaShoppingCart)`
    color             : ${({ scrollnav }) => (scrollnav ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)')};
`;

export const MobileIcon = styled.div`
    display         : none;

    @media screen and (max-width: 768px) {
        display     : block ;
        position    : absolute;
        top         : 0;
        right       : 0;
        transform   : translate(-100%, 60%);
        font-size   : 1.8rem;
        cursor      : pointer;
        color       : #fff;
    }
`;

export const NavMenu = styled.ul`
    display         : flex;
    align-items     : center;
    list-style-type : none;
    text-align      : center;
    margin-right    : -22px;

    /* @media screen and (max-width: 768px) {
        display     : none;
    } */
`;

export const NavItem = styled.li`
    height          : 120px;
`;

export const NavLinks = styled(LinkR)`
    color           : #fff;
    font-size       : 1.4rem;
    line-height     : 1;
    display         : flex;
    align-items     : center;
    text-decoration : none;
    text-transform  : uppercase;
    padding         : 0 0.5rem;
    height          : 100%;
    cursor          : pointer;

    &.active {
        border-bottom   : 3px solid #01bf71 ;
    }
`;

export const LogoutButton = styled.span`
    color           : #fff;
    font-size       : 1.4rem;
    line-height     : 1;
    display         : flex;
    align-items     : center;
    text-decoration : none;
    text-transform  : uppercase;
    padding         : 0 1rem;
    height          : 100%;
    cursor          : pointer;

    &.active {
        border-bottom   : 3px solid #01bf71 ;
    }
`;

export const NavLinkScroll = styled(LinkS)`
    color           : #fff;
    display         : flex;
    align-items     : center;
    text-decoration : none;
    text-transform  : uppercase;
    padding         : 0 1rem;
    height          : 100%;
    cursor          : pointer;

    &.active {
        border-bottom   : 3px solid #01bf71 ;
    }
`;
// export const NavBtn = styled.nav`
//     display             : flex;
//     align-items         : center;

//     @media screen and (max-width: 768px ) {
//         display         : none;
//     }
// `;

// export const NavBtnLink = styled(LinkR)`
//     border-radius       : 50px;
//     background          : #67d6ff;
//     white-space         : nowrap;
//     padding             : 10px 22px;
//     color               : #010606;
//     font-size           : 16px;
//     outline             : none;
//     border              : none;
//     cursor              : pointer;
//     transition          : all 0.2s ease-in-out;
//     text-decoration     : none;

//     &:hover {
//         transition      : all 0.2s ease-in-out;
//         background      : #fff;
//         color           : #010606;
//     }
// `;