import styled from "styled-components";

export const UserProfileDiv     = styled.div`
    display         : block;
    width           : 100%;
    margin          : 3rem auto 1rem;
`;

export const UserProfileUL      = styled.ul`
    margin          : 0;
    padding         : 0;
    list-style-type : none;
`;

export const UserProfileLI      = styled.li`
    display         : block;
    width           : 100%;
`;

export const UserProfileImgDiv  = styled.div`
    display         : block;
    width           : 5rem;
    margin          : 0 auto;
`;

export const UserProfileImg     = styled.img`
    display         : block;
    width           : 100%;
`;

export const UserProfileName    = styled.div`
    display         : block;
    width           : 100%;
    text-align      : center;
    margin          : 1rem auto;
    font-size       : 1.8rem;
    line-height     : 1;
    text-transform  : uppercase;
`;