import styled from 'styled-components';
import { AiFillMinusSquare, AiFillPlusSquare, AiFillCloseSquare } from 'react-icons/ai'

//Checkout container
export const CheckoutDiv = styled.div`
    h1 {
        font-size: 2rem;
        text-align: center;
    }

    .cart {
        table {
            border: 0;
            cellpadding: 0;
            cellspacing: 0;
            width: 100%;
        }
    }
`;

//Header of the Checkout table
export const CheckoutHeader = styled.table`
    background-color: #f6f6f6;
    th {
        font-size: 1.2rem;
        text-align: left;
        padding: 10px;
    }

    th {
        width: 20%;
    }
`;

//Items of the Checkout table
export const CheckoutItems = styled.table`

`;

//Total of the Checkout table
export const CheckoutTotal = styled.table`
    tr{
        td{
            h3{
                font-size: 1.2rem;
                text-align: left;
                padding: 10px;
            }
        }
    }
`

//Increase qty button for item
export const MinusSquare = styled(AiFillMinusSquare)`
    width:2rem;
    height:2rem;
    box-sizing: content-box;
`;

//Decrease qty button for item
export const PlusSquare = styled(AiFillPlusSquare)`
    width:2rem;
    height:2rem;
    box-sizing: content-box;
`;

//Remove item from cart button
export const CloseSquare = styled(AiFillCloseSquare)`
    width:2rem;
    height:2rem;
    box-sizing: content-box;
`;

export const CartItemTable = styled.table`
    border: 0;
    cell-spacing: 0;
    cell-padding: 10px;

    td {
        width: 20%;
    }
`;

export const CartBtn = styled.span`
    .cartBtnIcon {
        padding: 0;
        box-sizing: content-box;
    }
`;

export const CartImg = styled.img`
    width: 100px;
    height: 100px;
`;

export const CartQty = styled.span`
    margin: 0 10px;
`;

export const CartRemove = styled.span`
    .cartBtnIcon {
        padding: 0;
        box-sizing: content-box;
    }
`;



// import styled           from "styled-components";

// import {
//     AiFillCloseSquare,
//     AiFillPlusSquare,
//     AiFillMinusSquare } from 'react-icons/ai'

// export const CheckOutDiv = styled.div`
//     margin          : 2rem auto;
//     h1, p {
//         text-align  : center;
//     }

//     @media only screen and (max-width: 980px) {
//         display     : block;
//         width       : 100%;
//     }
// `;

// export const CheckOutH1 = styled.h1`
//     display         : block;
//     width           : 100%;
// `;

// export const CartDiv = styled.div`
//     max-width       : 100.0rem;
//     margin          : 0 auto;

//     table {
//         width       : 100%;
//     }
//     @media only screen and (max-width: 980px) {
//         td {
//             display : block;
//             width   : 100%;
//         }
//     }
// `;

// export const CartItemsTable = styled.table`
//         th, td { 
//             width   : 22%;
//         }
// `;

// export const CartTable = styled.table`

// `;

// export const CheckoutHeader = styled.table`
//     border-bottom   : 1px solid black;
//     #CartDiv {
//         width       : 100%;
//         text-align  : left;
//     }

//     @media only screen and (max-width: 980px) {
//         display     : none;
//     }
// `;

// export const CartItem = styled.table`
//     td img {
//         display     : block;
//         width       : 100%;
//     }

//     @media only screen and (max-width: 980px) {
//         border      : 1px solid black;
//     }
// `;

// export const MinusSquare = styled(AiFillMinusSquare)`
//     padding         : 0 1rem 0 0;
//     box-sizing      : content-box;
//     size            : 10rem;
// `;

// export const PlusSquare = styled(AiFillPlusSquare)`
//     padding         : 0 0 0 1rem;
//     box-sizing      : content-box;
// `;

// export const CloseSquare = styled(AiFillCloseSquare)`
//     box-sizing      : content-box;
// `;