import styled from "styled-components";

export const FormRow = styled.div`
    display         : inline-block;
    width           : 100%;
`;


export const Label = styled.label`
    display         : block;
    width           : 100%;
    text-align      : left;
    font-size       : medium;
`;
  
export const Select = styled.select`
    display         : block;
    width           : auto;
    float           : left;
    font-size       : 1.5rem;
    line-height     : 1;
    font-weight     : 400;
    text-align      : left;
    padding         : 5px 0px;
    margin          : 5px auto;
    border          : none;
    outline         : none;
    cursor          : pointer;
`;