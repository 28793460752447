import React from 'react'
import ProductsResults from '../../components/ProductsResults';
import { SearchPage } from './index.elements'

const Search = () => {
  return (
    <SearchPage>
        <ProductsResults/>
    </SearchPage>
  )
}

export default Search;