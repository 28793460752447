import React    from 'react';
import Footer   from '../components/Footer';
import Navbar   from './../components/Navbar';

const MainLayout = props => {
    return (
        <div className='fullHeight'>
            <Navbar{...props} />
            <div className="main">
                {props.children}
            </div>
            <Footer />
        </div>
    );
};

export default MainLayout