import React from 'react'
import Button from '../forms/Button'

const LoadMore = ({
    onLoadMoreEvt = () => { },
    disabled = false
}) => {
  return (
    <Button onClick={() => onLoadMoreEvt()} disabled={disabled} >
        Load More
    </Button>
  );
};

export default LoadMore