import styled from 'styled-components';

export const ProductList        = styled.ul`
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-between;
    list-style      : none;
    margin          : 0;
    padding         : 0;
`;

export const Product            = styled.li`
    width           : 30%;
    margin-bottom   : 1rem;
`;

export const ProductImage       = styled.img`
    width           : 100%;
`;

export const ProductName        = styled.h3`
    font-size       : 1.5rem;
    font-weight     : 600;
    margin-top      : 0.5rem;
    margin-bottom   : 0.5rem;
`;

export const ProductPrice       = styled.p`
    font-size       : 1.25rem;
    font-weight     : 600;
    color           : green;
`;

export const ProductDescription = styled.p`
    font-size       : 1.25rem;
    margin-bottom   : 0.5rem;
`;