import React from 'react';
import ProductCard from '../../components/ProductCard';
import { ProductDetailsDiv } from './index.elements';

const ProductDetails = () => {
  return (
    <ProductDetailsDiv>
      <ProductCard />
    </ProductDetailsDiv>
  )
}

export default ProductDetails;
