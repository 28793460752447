import React, { useState } from 'react'

import { Button } from '../ButtonElements';

import Video        from '../../assets/video/video2.mp4'
import {
    ArrowForward,
    ArrowRight,
    HeroBG,
    HeroBtnWrapper,
    HeroContainer,
    HeroContent,
    HeroH1,
    HeroP,
    VideoBG,

}                   from './HeroSection.elements'

const HeroSection = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

  return (
    <>
        <HeroContainer id="home">
            <HeroBG>
                <VideoBG playsInline autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBG>
            <HeroContent>
                <HeroH1>
                    Organizing Things
                </HeroH1>
                <HeroP>
                    Find out how organized you can be!
                </HeroP>
                <HeroBtnWrapper>
                <Button
                    to          ='signup'
                    smooth      ={true}
                    duration    ={500}
                    spy         ={true}
                    exact       ='true'
                    offset      ={-80}
                    primary     ='true'
                    dark        ='true'
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
          > 
                        Get Started {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    </>
  )
}

export default HeroSection