// import Street, { streetConverter } from "./Street";

/**
 * Class to create a ProviderData object
 */
 class ProviderData {
    /**
     *@param {string} uid           - User unique identifier
     *@param {string} [displayName] - User Prospects preferred name
     *@param {string} email         - User Prospects registered email
     *@param {number} [phoneNumber] - Users registered phone number
     *@param {string} [photoURL]    - User Prospects profile image url
     *@param {string} providerId    - Provider identifier
     */

    constructor(displayName, email, phoneNumber, photoURL, providerId, uid) {

        /**
         *@property {string} uid           - User unique identifier
         *@property {string} [displayName] - User Prospects preferred name
         *@property {string} email         - User Prospects registered email
         *@property {number} [phoneNumber] - Users registered phone number
         *@property {string} [photoURL]    - User Prospects profile image url
         *@property {string} providerId    - Provider identifier
         */

        this.uid            = uid;
        this.displayName    = displayName;
        this.email          = email;
        this.phoneNumber    = phoneNumber;
        this.photoURL       = photoURL;
        this.providerId     = providerId;

    }

    /**
     * Function ToDict
     * @property    {Function} ToDict Returns a dictionary format of
     * @returns     {Object}
     */

    ToDict() {
        return {
            "uid"           : this.uid,
            "displayName"   : this.displayName,
            "email"         : this.email,
            "phoneNumber"   : this.phoneNumber,
            "photoURL"      : this.photoURL,
            "providerId"    : this.providerId
        };
    }

    /**
     * Function IterateToDict
     * @property    {Function}              IterateToDict   Iterates through array of ProviderData and calls ToDict on each element
     * @property    {Array<ProviderData>}   providerData    The array of ProviderData objects to iterate through
     * @static
     * @returns     {Array<Object>}                         The array of objects mapped from each ProviderData element
     */

    static IterateToDict(providerData) {
        return providerData.map((element) => {
            return element.ToDict();
        })
    }


    /**
     * Function IterateFromDict
     * @property    {Function}              IterateFromDict   Iterates through array of ProviderData and calls ToDict on each element
     * @property    {Array<Object>}         providerData    The array of objects to iterate through
     * @static
     * @returns     {Array<ProviderData>}                   The array of ProviderData objects mapped from each object element
     */

     static IterateFromDict(providerData) {
        return providerData.map((element) => {
            return ProviderData.FromDict(element);
        })
    }

    /**
    * Function FromDict
    * @property {Function}      FromDict    Returns a dictionary format of a Provider Data Object
    * @property {Object}        data        The data from database
    * @static
    * @returns  {ProviderData}  The map object for export
    */

    static FromDict(data) {

        if (data == null) {
            return data
        }

        return new ProviderData(
            data.uid,
            data.displayName,
            data.email,
            data.phoneNumber,
            data.photoURL,
            data.providerId
        );

    }
}

/**
 * A ProviderData
 * @typedef     {FirebaseFirestore.FirestoreDataConverter} ProviderDataConverter
 * @property    {Function} toFirestore      - Function to send data to database
 * @property    {Function} fromFirestore    - Function to retrieve data from database
 */

/**
 * @type ProviderDataConverter
 */

export const providerDataConverter = {

    /**
     * Function toFirestore
     * @property    {Function}      toFirestore Function to send ProviderData object to database
     * @param       {ProviderData}  providerData ProviderData to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function (providerData) {

        return {

            uid         : providerData.uid,
            displayName : providerData.displayName,
            email       : providerData.email,
            phoneNumber : providerData.phoneNumber,
            photoURL    : providerData.photoURL,
            providerI   : providerData.providerId

        }
    },

    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore   Function to convert data from database into ProviderData object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot        Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options         Snapshot options
     * @returns     {ProviderData}
     */

    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);

        return new ProviderData(
            data.uid,
            data.displayName,
            data.email,
            data.phoneNumber,
            data.photoURL,
            data.providerId
        );
    }
}

export default ProviderData;