import styled from "styled-components";

// export const SignInElement = styled.div`
//     display     : block;
//     width       : 100%;
//     max-width   : 40.0rem;
//     margin      : 4rem auto 6rem;
//     border      : 1px solid black;
// `

// export const SignInWrap = styled.div`
//     padding         : 10px;
// `

// export const SignInH2   = styled.h2`
//     font-size       : 2.2rem;
//     line-height     : 1;
//     font-weight     : 400;
//     text-transform  : uppercase;
//     text-align      : center;
//     display         : block;
//     width           : 100%;
//     padding         : 0;
//     margin          : 0 auto 3rem;
// `

export const FormWrap = styled.div`
    margin: 3rem auto 0;
`

export const Form = styled.form`

`

export const SocialSignin = styled.div`
    margin: 1rem auto 0;
`

export const Row = styled.div`

`

// export const SignupA = styled.a`
//   text-align    : center;
//   margin-top    : 24px;
//   font-size     : 14px;
// `