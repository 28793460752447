
// index.elements.js
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 10px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

export const Name = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const JobTitle = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

export const CityState = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const LinkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  a {
    font-size: 14px;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-right: 10px;
  }
`;

export const SaveButton = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  background-color: lightblue;
  color: white;
  font-size: 16px;
`;
