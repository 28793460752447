import React, {
    useEffect }                 from 'react';

import {
        useNavigate,
        useParams }             from 'react-router';

import {
    useDispatch,
    useSelector }               from 'react-redux';

import { fetchProductsStart }   from '../../redux/Products/products.actions'; 
import FormSelect               from '../forms/FormSelect';
import {
    Header,
    ProductsDiv,
    Results }                   from './index.elements';

import Product                  from './Product';
import LoadMore                 from '../LoadMore';

const mapState = ({ productsData }) => ({
    products: productsData.products
});

const ProductsResults = ({}) => {
    const dispatch          = useDispatch();
    const navigate          = useNavigate();
    const { filterType }    = useParams();
    const { products }      = useSelector(mapState);
    const { data, queryDoc, isLastPage }          = products;

    useEffect(() => {
        dispatch(
            fetchProductsStart({ filterType })
        )
    }, [filterType]);

    const handleFilter = (e) => {
        const nextFilter = e.target.value;
        navigate(`/search/${nextFilter}`);
    };

    if (!Array.isArray(data)) return null;
    if (data.length < 1) {
        return (
            <div className="products">
                <p>
                    No search results.
                </p>
            </div>
        );
    }

    const configFilters = {
        defaultValue: filterType,
        options: [{
            name: 'Show All',
            value: ''
        }, {
            name: 'Mens',
            value: 'mens'
        }, {
            name: 'Womens',
            value: 'womens'
        }],
        handleChange: handleFilter
    }

    const handleLoadMore = () => {
        dispatch(
            fetchProductsStart({
                filterType,
                startAfterDoc: queryDoc,
                persistProducts: data })
        )
    };

    const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
    }

  return (
    <ProductsDiv>
        <Header>
            Browse Products
        </Header>

        <FormSelect {...configFilters} />

        <Results>
        {data.map((product, pos) => {
            const { productThumbnail, productName, productPrice } = product;
            if(!productThumbnail || !productName || typeof productPrice === 'undefined') return null;

            const configProduct = {
                ...product
            }
            return (
                <Product {...configProduct} />
            )
        })}
        </Results>

        {!isLastPage && (
            <LoadMore disabled={isLastPage} {...configLoadMore} />
        )}

    </ProductsDiv>
  );
};

export default ProductsResults;