import styled               from "styled-components";
import { CountryDropdown }  from "react-country-region-selector";

export const FormRow = styled.div`
    height: auto;
`;

export const FormLabel = styled.label`
    height: auto;
    font-size: medium;
`;

export const FormCountrySelectorView = styled(CountryDropdown)`
    display     : block;
    width       : 100%;
    font-size   : 1.5rem;
    line-height : 1;
    font-weight : 400;
    text-align  : left;
    padding     : 5px 5px;
    margin      : 5px auto;
    border      : 1px solid #9e9e9e;
    outline     : none;
`;