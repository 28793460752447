import React, { useEffect }   from 'react';
import {
  useNavigate,
  useParams }                 from 'react-router';
import {
  AddToCart,
  HeroDiv,
  HeroImage,
  ProductCardDiv,
  ProductDescription,
  ProductDetails,
  ProductName,
  ProductPrice }              from './index.elements';
import {
  fetchProductStart,
  setProduct }                from '../../redux/Products/products.actions';
import {
  useDispatch,
  useSelector }               from 'react-redux';
import Button                 from '../forms/Button';
import { addProduct } from '../../redux/Cart/cart.actions';

const mapState = state => ({
  product : state.productsData.product
});

const ProductCard = ({}) => {
  const dispatch      = useDispatch();
  const navigate      = useNavigate();
  const { productID } = useParams();
  const { product }   = useSelector(mapState);

  const {
    productThumbnail,
    productName,
    productPrice,
    productDescription,
  } = product;

  useEffect(() => {
    dispatch(
      fetchProductStart(productID)
    )

    return () => {
      dispatch(
        setProduct({
          
        })
      )
    }

  }, []);

  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(
      addProduct(product)
    )
    navigate('/store')
  }

  const configAddToCartBtn = {
    type  : 'button'
  }

  return (
    <ProductCardDiv >
        <HeroDiv>
          <HeroImage src={productThumbnail} />
        </HeroDiv>
        <ProductDetails>
          <ul>
            <li>
              <ProductName>
                {productName}
              </ProductName>
            </li>
            <li>
              <ProductPrice>
                ${productPrice}
              </ProductPrice>
            </li>
            <li>
              <AddToCart>
                <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)} >
                  Add To Cart
                </Button>
              </AddToCart>
            </li>
          </ul>
        </ProductDetails>
        <ProductDescription dangerouslySetInnerHTML={{ __html: productDescription }} />
    </ProductCardDiv>
  )
}

export default ProductCard;