import React            from 'react';
// import NavbarLimited    from '../components/NavbarLimited';
import Navbar           from '../components/Navbar';
import Footer           from '../components/Footer';

const LimitedLayout = props => {
  return (
    <>
        {/* <NavbarLimited {...props} /> */}
        <Navbar {...props}/>
        {props.children}
        <Footer />
    </>
  )
}

export default LimitedLayout