import React, {
	useState,
	useEffect }  			from 'react';
import {
	addProductStart,
	deleteProductStart,
	fetchProductsStart } 	from '../../redux/Products/products.actions';
import Modal          		from './../../components/Modal';
import FormInput      		from '../../components/forms/FormInput';
import FormSelect     		from '../../components/forms/FormSelect';
import Button         		from '../../components/forms/Button';
import LoadMore				from './../../components/LoadMore';
import { CKEditor }			from 'ckeditor4-react';
import {
	AddNewProductForm,
	AddNewProductFormDiv,
	AdminPage,
	CallToActions, 
	ManageProducts,
	TableButton}  			from './index.elements'
import {
	useDispatch,
	useSelector } 			from 'react-redux';

const mapState = ({ productsData }) => ({
	products: productsData.products
});

const Admin = props => {

	const { products } 										= useSelector(mapState);
    const [hideModal, 			setHideModal			] 	= useState(true);
    const [productCategory,		setProductCategory		] 	= useState('mens');
    const [productName,       	setProductName			] 	= useState('');
    const [productThumbnail,  	setProductThumbnail		]	= useState('');
    const [productPrice,      	setProductPrice			]	= useState(0);
	const [productDescription,	setProductDescription	]	= useState('');

	const { data, queryDoc, isLastPage } = products;

	useEffect(() => {
		dispatch(
			fetchProductsStart()
		);

	}, []);

	const toggleModal 	= () => setHideModal(!hideModal);
	const dispatch 		= useDispatch();

	const configModal 	= {
		hideModal,
		toggleModal
	};

	const resetForm 	= () => {
		setHideModal(true);
		setProductCategory('mens');
		setProductName('');
		setProductThumbnail('');
		setProductPrice(0);
		setProductDescription('');
	};

	const handleSubmit 	= e => {
		e.preventDefault();
		dispatch(
			addProductStart({
				productCategory,
				productName,
				productThumbnail,
				productPrice,
				productDescription,
			})
		);
		resetForm();
	};

	const handleLoadMore = () => {
		dispatch(
			fetchProductsStart({
				startAfterDoc: queryDoc,
				persistProducts: data
			})
			
		);
	};
	const configLoadMore = {
		onLoadMoreEvt: handleLoadMore,
	};

        return (
            <AdminPage>
				<CallToActions>
					<ul>
						<li>
							<Button onClick={() => toggleModal()}>
								Add New Product
							</Button>
						</li>
					</ul>
				</CallToActions>

				<Modal {...configModal}>
					<AddNewProductFormDiv>
						<AddNewProductForm onSubmit={handleSubmit}>

							<h2>
								Add new product
							</h2>

							<FormSelect
							label			="Category"
							options			={[{
												value	: "mens",
												name	: "Mens"
											},{
												value	: "womens",
												name	: "Womens"
											}]}
							handleChange	={e => setProductCategory(e.target.value)}
							/>

							<FormInput
							label			="Name"
							type			="text"
							value			={productName}
							handleChange	={e => setProductName(e.target.value)}
							/>

							<FormInput
							label			="Main Image URL"
							type			="url"
							value			={productThumbnail}
							handleChange	={e => setProductThumbnail(e.target.value)}
							/>

							<FormInput
							label			="Price"
							type			="number"
							min				="0.00"
							max				="10000.00"
							step			="0.01"
							value			={productPrice}
							handleChange	={e => setProductPrice(e.target.value)}
							/>

							<CKEditor 
								onChange={evt => setProductDescription(evt.editor.getData())}
							/>

							<br />

							<Button type="submit">
								Add Product
							</Button>

						</AddNewProductForm>
					</AddNewProductFormDiv>
				</Modal>
				<ManageProducts>
					<table border="0" cellPadding="0" cellSpacing="0">
						<tbody>
							<tr>
								<th>
									<h1>
										Manage Products
									</h1>
								</th>
							</tr>
							<tr>
								<td>
									<table className='results' border="0" cellPadding="10" cellSpacing="0">
										<tbody>
											{(Array.isArray(data) && data.length > 0) && data.map((product, index) => {
												const {
													productName,
													productThumbnail,
													productPrice,
													documentID
												} = product;
												return (
													<tr key={index}>
														<td>
															<img className="thumb" src={productThumbnail} alt='Product thumbnail' />
														</td>
														<td>
															{productName}
														</td>
														<td>
															$ {productPrice}
														</td>
														<td>
															<div>
															<TableButton >
																Edit
															</TableButton>
															</div>
															<div>
															<TableButton onClick={() => dispatch(deleteProductStart(documentID))}>
																Delete
															</TableButton>
															</div>
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</td>
							</tr>
							<tr>
								<td>

								</td>
							</tr>
							<tr>
								<td>
									<table border='0' cellPadding='10' cellSpacing='0'>
										<tbody>
											<tr>
												<td>
													{!isLastPage && (
														<LoadMore {...configLoadMore} />
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</ManageProducts>
            </AdminPage>
      )
}

export default Admin;