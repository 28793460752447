import React    from 'react';
import CheckOut from '../../components/CheckOut';

const Cart = () => {
  return (
    <div>
        <CheckOut />
    </div>
  );
}

export default Cart;