import React, {
    useState,
    useEffect }                     from 'react';

import {
    useDispatch,
    useSelector }                   from 'react-redux';

import { useNavigate }              from 'react-router';
import Button                       from '../forms/Button';
import FormInput                    from '../forms/FormInput';
import {
    FormWrap,
    Form}                           from './index.elements';

import AuthWrapper                  from '../AuthWrapper';
import { signUpUserStart }          from '../../redux/User/user.actions';

const mapState = ({ user }) => ({
    currentUser : user.currentUser,
    userErr     : user.userErr
});

const Signup = props => {

    const { currentUser, userErr }                  = useSelector(mapState);
    const dispatch                                  = useDispatch();
    const [displayName,     setDisplayName      ]   = useState('');
    const [email,           setEmail            ]   = useState('');
    const [password,        setPassword         ]   = useState('');
    const [confirmPassword, setConfirmPassword  ]   = useState('');
    const [errors,          setErrors           ]   = useState([]);
    const navigate                                  = useNavigate();

    useEffect(() => {
        if (currentUser) {
            reset();
            navigate('/');
        }
    }, [currentUser]);

    useEffect(() => {
        if (Array.isArray(userErr) && userErr.length > 0) {
            setErrors(userErr);
        }
    }, [userErr]);

    const reset = () => {
        setDisplayName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setErrors([]);
    };

    const handleFormSubmit = event => {
        event.preventDefault();

        dispatch(signUpUserStart({
            displayName,
            email,
            password,
            confirmPassword
        }));
    }

        const configAuthWrapper = {
            headline      : "Sign Up",
            destination1  : "Already have an account?",
            href1         : "/login",
          }

        return (
            <AuthWrapper {...configAuthWrapper}>


                    <FormWrap>

                        {errors.length > 0 && (
                            <ul>
                                {errors.map((err, index) => {
                                    return(
                                        <li key={index}>
                                            {err}
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                        <Form onSubmit={handleFormSubmit}>
                        <FormInput
                            type        ="text"
                            name        ="displayName"
                            value       ={displayName}
                            placeholder ="Full Name"
                            handleChange={e => setDisplayName(e.target.value)} />

                        <FormInput
                            type        ="text"
                            name        ="email"
                            value       ={email}
                            placeholder ="Email"
                            handleChange={e => setEmail(e.target.value)} />

                        <FormInput
                            type        ="password"
                            name        ="password"
                            value       ={password}
                            placeholder ="Password"
                            handleChange={e => setPassword(e.target.value)} />

                        <FormInput
                            type        ="password"
                            name        ="confirmPassword"
                            value       ={confirmPassword}
                            placeholder ="Confirm Password"
                            handleChange={e => setConfirmPassword(e.target.value)} />
                        <Button type="submit">
                            Register
                        </Button>
                        </Form>
                    </FormWrap>
                </AuthWrapper>
          )
  
}

export default Signup;