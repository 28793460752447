import React, {
  useState,
  useEffect }                         from 'react'

import {
  useDispatch,
  useSelector }                       from 'react-redux';

import {
  emailSignInStart,
  googleSignInStart }                 from '../../redux/User/user.actions';

import {
  FormWrap,
  Form,
  SocialSignin,
  Row }                               from './index.elements';
  
import AuthWrapper                    from '../AuthWrapper';
import Button                         from '../forms/Button';
import FormInput                      from '../forms/FormInput';
import { useNavigate }                from 'react-router';

const mapState = ({ user }) => ({
  currentUser: user.currentUser
});

const SignIn = props => {

  const { currentUser }           = useSelector(mapState);
  const navigate                  = useNavigate();
  const dispatch                  = useDispatch();
  const [email,     setEmail    ] = useState('');
  const [password,  setPassword ] = useState('');


  useEffect(() => {
    if (currentUser) {
      resetForm();
      navigate('/')
    }
  }, [currentUser]);

  const resetForm = () => {
    setEmail('');
    setPassword('');
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(emailSignInStart({ email, password }));
  }

    const configAuthWrapper = {
      headline      : "Login",
      destination1  : "Forgot Password?",
      href1         : "/recovery",
      destination2  : "Don't have an Account?",
      href2         : "/signup"
    }

    const handleGoogleSignIn = () => {
      dispatch(googleSignInStart());
    }

    return (
      <AuthWrapper {...configAuthWrapper}>
          <FormWrap>
            <Form onSubmit={handleSubmit}>
              <FormInput 
              type          = "email"
              name          = "email"
              value         = {email}
              placeholder   = "Email"
              handleChange  = {e => setEmail(e.target.value)}
              />

              <FormInput 
              type          = "password"
              name          = "password"
              value         = {password}
              placeholder   = "Password"
              handleChange  = {e => setPassword(e.target.value)}
              />

              <Button type="submit">
                Login
              </Button>

              <SocialSignin>
                <Row>
                  <Button onClick={handleGoogleSignIn}>
                    Sign in with Google
                  </Button>
                </Row>
              </SocialSignin>
            </Form>
          </FormWrap>
          </AuthWrapper>
    )
  
}

export default SignIn