export const homeObj1 = {
  id            : 'about',
  destination   : 'discover',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Why Luckey Living?',
  headline      : 'A Refreshing Reset, Realize your Potential',
  description   : 'We often become overwhelmed with clutter and disorganization. Let us organize and maximize your spaces and processes.',
  buttonLabel   : 'Get Started',
  imgStart      : false,
  img           : require('../../assets/images/sorting.svg').default,
  alt           : 'GlobePins',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj2 = {
  id            : 'discover',
  destination   : 'discover2',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Consultations',
  headline      : 'Taking the First Step',
  description   : 'Together we will tour and discuss your area and processes and explore the things that work as well as the things that do not work. We will discuss your expectations and goals and create an action plan that best suites your needs.',
  buttonLabel   : 'Learn More',
  imgStart      : true,
  img           : require('../../assets/images/tour.svg').default,
  alt           : 'Accounts',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const homeObj3 = {
  id            : 'discover2',
  destination   : 'signup',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Included Services',
  headline      : 'What do you get with our service?',
  description   : 'With our service you can expect the best product recommendations. When you have chosen your products, we will purchase your products directly through us. After we have perfected your space, we will also handle your donation drop offs.',
  buttonLabel   : 'Learn More',
  imgStart      : false,
  img           : require('../../assets/images/services.svg').default,
  alt           : 'Accounts',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj4 = {
  id            : 'signup',
  destination   : '/signup',
  isScroll      : false,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Lets Start Today',
  headline      : 'Scheduling your consult is  only a click away',
  description   : "Share your vision, and 10 minutes of your time. All you need to do is add your information and you're ready to go.",
  buttonLabel   : 'Sign Up and Start Now',
  imgStart      : true,
  img           : require('../../assets/images/global.svg').default,
  alt           : 'Papers',
  dark          : false,
  primary       : false,
  darkText      : true
};


export const homeObj5 = {
  id            : 'store',
  destination   : '/store',
  isScroll      : false,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Need Our Products?',
  headline      : 'Order products directly from our store!',
  description   : 'From our aesthetic organizer totes, to custom signs and containers, order our products directly from our ecommerce store!',
  buttonLabel   : 'Visit Our Online Store',
  imgStart      : false,
  img           : require('../../assets/images/ecommerce.svg').default,
  alt           : 'Store',
  dark          : true,
  primary       : true,
  darkText      : false
};