import React from 'react'
import SignIn from '../../components/SignIn'

const LoginPage = props => {
  return (
    <div>
        <SignIn />
    </div>
  )
}

export default LoginPage