export const sampleUser = {
    name: 'Sara Brown',
    description: 'Luckey Living Professional Organizing',
    jobTitle: 'Founder/CEO',
    city: 'Luckey',
    state: 'OH',
    image: 'https://firebasestorage.googleapis.com/v0/b/luckey-living.appspot.com/o/Users%2F90IAT4y8DFdyTJPCQxhfKDhHnik2%2Fsara.jpeg?alt=media&token=e7dde1f3-d80e-407c-b29a-548e35f45302',
    links: [
      {
        name: 'LinkedIn',
        url: 'https://linkedin.com/',
      },
      {
        name: 'Facebook',
        url: 'https://facebook.com/',
      },
      {
        name: 'Website',
        url: 'https://luckeyliving.com',
      },
    ],
  };