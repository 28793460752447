import React, { useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../assets/video/video2.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './elements';



const PageNotReady = ({
  headline,
  description,
  actionText
}) => {

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
  <HeroContainer id='home'>
    <HeroBg>
      <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
    </HeroBg>
    <HeroContent>
      <HeroH1>{headline}</HeroH1>
      <HeroP>{description}</HeroP>
      <HeroBtnWrapper>
        <Button
          to            ='signup'
          smooth        ={true}
          duration      ={500}
          spy           ={true}
          exact         ='true'
          offset        ={-80}
          primary       ='true'
          dark          ='true'
          onMouseEnter  ={onHover}
          onMouseLeave  ={onHover}
        >
          {actionText} {hover ? <ArrowForward /> : <ArrowRight />}
        </Button>
      </HeroBtnWrapper>
    </HeroContent>
  </HeroContainer>

  )
}

export default PageNotReady;
