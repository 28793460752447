import React                        from 'react';
import { useSelector }              from 'react-redux';
import { useNavigate }              from 'react-router';
import { VStack }                   from '../SwiftUIComponents';
import {
  selectCartItems,
  selectCartTotal }                 from '../../redux/Cart/cart.selectors';
import { createStructuredSelector } from 'reselect';
import Button                       from '../forms/Button';
import Item                         from './Item';
import {
  CheckoutDiv,
  CheckoutHeader,
  CheckoutItems,
  CheckoutTotal }                   from './index.elements';

const mapState = createStructuredSelector({
    cartItems : selectCartItems,
    total     : selectCartTotal,
});

const CheckOut = () => {
    const navigate              = useNavigate();
    const { cartItems, total }  = useSelector(mapState);

    const errMsg = 'You have no items in your cart.';

    return (
      <CheckoutDiv>
          <h1>Checkout</h1>
          <div className="cart">
              {cartItems.length > 0 ? (
                  <CheckoutItems>
                      <CheckoutHeader>
                          <tr>
                              <th>Product</th>
                              <th>Description</th>
                              <th>qty</th>
                              <th>Price</th>
                              <th>Remove</th>
                          </tr>
                      </CheckoutHeader>
                      <tbody>
                          {cartItems.map((item, pos) => (
                              <tr key={pos}>
                                  <td>
                                      <Item {...item} />
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </CheckoutItems>
              ) : (
                  <p>{errMsg}</p>
              )}
          </div>
          <CheckoutTotal>
              <tr>
                  <td>
                      <h3>Total: ${total}</h3>
                  </td>
              </tr>
          </CheckoutTotal>
          <VStack>
              <Button
                  type="button"
                  onClick={() => navigate('/payment')}
              >
                  Go to Payment
              </Button>
              <Button
                  type="button"
                  onClick={() => navigate('/store')}
              >
                  Continue Shopping
              </Button>
          </VStack>
      </CheckoutDiv>
  );
  
  };

export default CheckOut;
//*************************************************************************
// import React                        from 'react';
// import { useSelector }              from 'react-redux';
// import {
//   VStack,
//   HStack,
//   Spacer }                          from '../SwiftUIComponents';
// import {
//     selectCartItems,
//     selectCartTotal }               from '../../redux/Cart/cart.selectors';
// import { createStructuredSelector } from 'reselect';
// import Button                       from '../forms/Button';
// // import {
// //     CartDiv,
// //     CartItemsTable,
// //     CartTable,
// //     CheckOutDiv,
// //     CheckOutH1, 
// //     CheckoutHeader}                 from './index.elements';
// import Item                         from './Item';
// import { useNavigate }              from 'react-router';
// import                                  './styles.scss';

// const mapState = createStructuredSelector({
//     cartItems   : selectCartItems,
//     total       : selectCartTotal
// });

// const CheckOut = () => {

//     const navigate              = useNavigate();
//     const {
//       cartItems,
//       total }                 = useSelector(mapState);
      
//       const errMsg                = 'You have no items in your cart.';
      
//       return (
//           <div className="checkout">
//             <h1>
//               Checkout
//             </h1>
      
//             <div className="cart">
//               {cartItems.length > 0 ? (
//                 <table border="0" cellPadding="0" cellSpacing="0">
//                   <tbody>
//                     <tr>
//                       <td>
//                         <table className="checkoutHeader" border="0" cellPadding="10" cellSpacing="0">
//                           <tbody>
//                             <tr>
//                               <th>
//                                 Product
//                               </th>
//                               <th>
//                                 Description
//                               </th>
//                               <th>
//                                 qty
//                               </th>
//                               <th>
//                                 Price
//                               </th>
//                               <th>
//                                 Remove
//                               </th>
//                             </tr>
//                           </tbody>
//                         </table>
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <table border="0" cellSpacing="0" cellPadding="0">
//                           <tbody>
//                             {cartItems.map((item, pos) => {
//                               return (
//                                 <tr key={pos}>
//                                   <td>
//                                     <Item {...item} />
//                                   </td>
//                                 </tr>
//                               );
//                             })}
//                           </tbody>
//                         </table>
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <table border="0" cellSpacing="0" cellPadding="0">
//                           <tbody>
//                             <tr>
//                               <td>
//                                 <table border="0" cellPadding="10" cellSpacing="0">
//                                   <tbody>
//                                     <tr>
//                                       <td>
//                                       <h3>
//                                         Total: ${total}
//                                       </h3>
//                                       </td>
//                                     </tr>
//                                   </tbody>
//                                 </table>
//                               </td>
//                             </tr>
//                             <tr>
//                               <td>
//                                 <table border="0" cellPadding="10" cellSpacing="0">
//                                   <tbody>
//                                     <tr>
//                                       <td>
//                                         <Button onClick={() => navigate(-1)}>
//                                           Continue Shopping
//                                         </Button>
//                                       </td>
//                                       <td>
//                                         <Button onClick={() => navigate('/payment')}>
//                                           Checkout
//                                         </Button>
//                                       </td>
//                                     </tr>
//                                   </tbody>
//                                 </table>
//                               </td>
//                             </tr>
//                           </tbody>
//                         </table>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               ) : (
//                   <p>
//                     {errMsg}
//                   </p>
//                 )}
//             </div>
//           </div>
//         );

    // return (
    //   <div>
    //   {cartItems.length > 0 ? (
    //   <VStack>
        
    //       <h1>
    //         Checkout
    //       </h1>
    //     <HStack>
    //       <h1>Product</h1>
    //       <h1>Description</h1>
    //       <h1>Qty</h1>
    //       <h1>Price</h1>
    //       <h1>Remove</h1>
    //     </HStack>
    //     {cartItems.map((item, pos) => {
    //                         return (
    //                           <HStack>
    //                             <Item {...item} />
    //                           </HStack>
                             
    //                         );
    //                       })}
    //       <HStack>
    //         <h3>
    //           Total: ${total}
    //         </h3>
    //       </HStack>
    //       <HStack>
    //         <Button onClick={() => navigate(-1)}>
    //           Continue Shopping
    //         </Button>
                                      
    //         <Button onClick={() => navigate('/payment')}>
    //           Checkout
    //         </Button>
    //       </HStack>
    //   </VStack>

              
    //         ) : (
    //             <p>
    //               {errMsg}
    //             </p>
    //           )}
    //   </div>
    // );





//   return (
//     <CheckOutDiv>
//         <CheckOutH1>
//             Check Out
//         </CheckOutH1>

//         <CartDiv>
//             <CartTable border='0' cellPadding='0' cellSpacing='0' >
//                 <tbody>
                    
//                     <tr>
//                         <CheckoutHeader border='0' cellPadding='0' cellSpacing='0' >
//                             <tbody>
//                                 <tr>
//                                     <th>
//                                         Product
//                                     </th>
//                                     <th>
//                                         Description
//                                     </th>
//                                     <th>
//                                         Qty
//                                     </th>
//                                     <th>
//                                         Price
//                                     </th>
//                                     <th>
//                                         Remove
//                                     </th>
//                                 </tr>
//                             </tbody>
//                         </CheckoutHeader>
//                     </tr>

//                     <tr>
//                         <CartItemsTable border='0' cellPadding='0' cellSpacing='0'>
//                             <tbody>
//                                 {cartItems.map((item, pos) => {
//                                     return ( 
//                                         <tr>
//                                             <td>
//                                                 <Item {...item} />
//                                             </td>
//                                         </tr>
//                                     )
//                                 })}
//                             </tbody>
//                         </CartItemsTable>
//                     </tr>
//                     <tr>
//                         <td>
//                             <table align='right' border='0' cellSpacing='0' cellPadding='10'>
//                                 <tbody>
//                                     <tr align='right'>
//                                         <td>
//                                             <h3>
//                                                 Total:
//                                             </h3>
//                                         </td>
//                                     </tr>

//                                     <tr>
//                                         <td>
//                                             <table border='0' cellSpacing='0' cellPadding='10' >
//                                                 <tbody>
//                                                     <tr>
//                                                         <td>
//                                                             <Button>
//                                                                 Continue Shopping
//                                                             </Button>
//                                                         </td>
//                                                         <td>
//                                                             <Button>
//                                                                 Checkout
//                                                             </Button>
//                                                         </td>
//                                                     </tr>
//                                                 </tbody>
//                                             </table>
//                                         </td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                         </td>
//                     </tr>

//                 </tbody>
//             </CartTable>
//         </CartDiv>
//     </CheckOutDiv>
//   )
// }

// export default CheckOut;