import React            from 'react';
import { useSelector }  from 'react-redux';
import UserProfile      from './../UserProfile';
import {
    VerticalNavDiv,
    Menu }              from './index.elements';

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const VerticalNav = ({ children }) => {
    const { currentUser } =  useSelector(mapState);

    const configUserProfile = {
        currentUser
    }

  return (
    <VerticalNavDiv>
        
        <UserProfile {...configUserProfile} />

        <Menu>
            {children}
        </Menu>
    </VerticalNavDiv>
  );
}

export default VerticalNav