import React from 'react';
import Icon1 from '../../assets/images/house.jpg';
import Icon2 from '../../assets/images/business.jpg';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './services.elements.js';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Residential Home Organizing</ServicesH2>
          <ServicesP>
          Organizing homes from the garage to the basement and attic. Room by room or whole home.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Business Organizing</ServicesH2>
          <ServicesP>
          Starting a new project, expanding, or just need an overhaul? We will organize your business spaces for optimal use.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
